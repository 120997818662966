import { getActivePinia } from 'pinia'

import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'
import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

export default defineNuxtRouteMiddleware(async () => {
  const { $localePath, $sentry } = useNuxtApp()
  const pinia = getActivePinia()
  const userStore = useUserStore(pinia)

  if (!userStore.id) return navigateTo($localePath('/influencer/signup'))

  // TODO: need to check if user is influencer
  // and redirect to home if not

  const tagStore = useTagStore(pinia)
  const userInfluencerTagsStore = useUserInfluencerTagsStore(pinia)

  try {
    await Promise.all([tagStore.FETCH(), userInfluencerTagsStore.FETCH()])
  } catch (error) {
    $sentry?.captureException(error)
  }
})
